<template>
	<div>
		<pui-datatable v-if="!masterDetail"
			:modelName="modelName"
			:actions="actions"
			:modelColumnDefs="modelColumnDefs"
			:showCreateBtn="false"
			:showDeleteBtn="false"
			readOnly
		></pui-datatable>
		<pui-datatable
			v-else
			:modelName="modelName"
			:modelColumnDefs="modelColumnDefs"
			:externalFilter="externalFilter"
			:masterDetail="masterDetail"
			:parentModelName="parentModelName"
			:masterDetailColumnVisibles="masterDetailColumnVisibles"
			:modalDialog="modalDialog"
			:showDeleteBtn="false"
			:navigableDetail="true"
			:showCreateBtn="showCreateBtn"
		></pui-datatable>
		<resolucion-modals
			:modelName="modelName"
		></resolucion-modals>
	</div>
</template>

<script>
import resolucionActions from './ResolucionActions';
import resolucionModals from './ResolucionModals.vue';
import PuiGridDetailMixin from 'pui9-mixins/PuiGridDetailMixin';
import PuiModelConfigurationMixin from 'pui9-datatables/src/mixins/PuiModelConfigurationMixin';

export default {
	name: 'resolucion-grid',
	mixins: [PuiGridDetailMixin, PuiModelConfigurationMixin],
	components: {
		'resolucion-modals': resolucionModals
	},
	data() {
		return {
			modelName: 'resolucion',
			actions: resolucionActions.gridactions,
			modelColumnDefs: {
			},
			masterDetailColumnVisibles: {
				idres:true,
				tipres:true,
				fecinicioaut:true,
				areaopera:true,
				observaciones:true
			}
		}
	},
	created(){
		this.$store.dispatch('loadDefaultGridConfiguration', this.modelName);

		/*const currentConfig = this.$store.getters.getCurrentGridConfiguration(this.modelName);
		if(!currentConfig){
			const defaultConfig = this.$store.getters.getDefaultGridConfiguration(this.modelName);
			if (defaultConfig) {
				this.gridConfiguration = this.cloneConfiguration(defaultConfig);
				this.$store.commit('puiModelsSetCurrentGridConfiguration', { model: this.modelName, configuration: this.gridConfiguration });
			}
		}*/
	},
	computed: {
		showCreateBtn() {
			//return registry && registry.idestado===1;

			//boton nuevo solo si estado notificación es autorizada, aceptada o denegada
			const modelnotres = this.$store.getters.getModelByName('resolucion');
			if(modelnotres){
				var idestadonot = modelnotres.idestadonotificacion;
				return idestadonot===2 || idestadonot===3 || idestadonot===4;
			}

			return false;

		}
	}
}


</script>
